import React from 'react'
import { useAddress } from "@thirdweb-dev/react";
import { ConnectWallet, darkTheme } from "@thirdweb-dev/react";

const NavBar = () => {

  const address = useAddress();


  if (!address) {
    return (
      <div className="navbar dashboardbg">
    <div className="navbar-start">
      <div className='flex'>
      <img src="https://frogspepe.xyz/fpepe.png" alt="" width={'84px'}></img>
      </div>
    </div>
   
    <div className="navbar-end">
    <ConnectWallet
        theme={"dark"}
        style={{ color: "white", background:"green" }}
        className="lg:hidden"
        btnTitle={"Connect"}
        modalTitle={"Authenticate Wallet"}
        switchToActiveChain={true}
        modalSize={"compact"}
        welcomeScreen={{}}
        termsOfServiceUrl={
          "https://frogspepe.xyz"
        }
        privacyPolicyUrl={
          "https://frogspepe.xyz"
        }
      />
      </div>
  </div>
    );
  }


  return (
    <div className="navbar dashboardbg">
    <div className="navbar-start">
      
      <div className='flex'>
      <img src="https://frogspepe.xyz/images/frogs.png" alt="" width={'84px'}></img>
      </div>
    </div>
    <div className="navbar-center hidden lg:flex">
     
    </div>
    <div className="navbar-end">
    <ConnectWallet
        theme={"dark"}
        className="lg:hidden"
        btnTitle={"Connect Wallet"}
        modalTitle={"Authenticate Wallet"}
        switchToActiveChain={true}
        modalSize={"compact"}
        welcomeScreen={{}}
        termsOfServiceUrl={
          "https://frogspepe.xyz"
        }
        privacyPolicyUrl={
          "https://frogspepe.xyz"
        }
      />
      </div>
  </div>
  
  )
}

export default NavBar