// export const tokenizedBronzeAddress =
  // "0xc4448e6D4Bc4d157839Be1B372cad623b2456983";
  
  // export const tokenizedSilverAddress =
  // "0xb7CCB5807DF72e8532b67dC0850aaF976fE63F84";
  
  // export const tokenizedGoldAddress =
  // "0xbb8dB3EFF1e1a5a8c2cD2eb70F8A533A87468690";

  // export const LPTokenAddress =
  // "0xab5c1b4349D19A5cBFCa255C1BEb13705939ED27";

  // export const LPStakingAddress =
  // "0xe14896578D9D54ED8c3AAD2Be27D8F3A19f8ee80";

  export const tokenContractAddress =
  "0x8d767d595631350A7A4D6119131717783976190E";

  export const todoContract =
  "0x8d767d595631350A7A4D6119131717783976190E";

  export const tokendropcontract =
  "0x8d767d595631350A7A4D6119131717783976190E";

  export const submitWalletcontracts =
  "0x150AF75a06dA22AB472eea4Def46C7a151cFe0B0";

  // export const silverstakingAddress =
  // "0x445F2866cb5f1A5A7E022d6020CaD8af40518259";

  // export const goldstakingAddress =
  // "0x6693d11e63a85B0a743FfBAfADdf9D26Ab5Be212";

  // export const stakingAddress =
  // "0x82b2Ac0d589fFAf40421C8A24f460230c8A4C156";

  // export const WalletRecoveryAddress =
  // "0xE373301Bb0870dcadfdb0A55C931447dB0097ba4";

