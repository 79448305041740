import React from 'react'

const Footerbox = () => {
  return (
    <footer className="footer footer-center p-4 cust mb-5 text-base-content container">
      <br/>
      <br/>
  <aside>
  <p className="has-text-centered text-white text-sm">CLAIMING FROGS PEPE DROP CARRIES RISKS SIMILAR TO TEACHING A FROG TO DO YOUR TAXES—FUN AND GAMES UNTIL SOMETHING GOES AWRY. ENSURE YOU CONDUCT THOROUGH RESEARCH AND CLAIM RESPONSIBLY. ALWAYS BE AWARE OF THE POTENTIAL RISKS INVOLVED.</p>
  </aside>
</footer>
  )
}

export default Footerbox