import "./styles/Home.css";
import NavBar from "./components/NavBar";
import { ContractMetadata, Web3Button, useActiveClaimConditionForWallet, useAddress, useClaimConditions, useClaimIneligibilityReasons, useClaimerProofs, useContractMetadata, useContractRead, useTokenSupply } from "@thirdweb-dev/react";
import { ConnectWallet, darkTheme } from "@thirdweb-dev/react";
import { tokendropcontract, submitWalletcontracts } from "./const/contractAddresses";
import { useEffect, useMemo, useState } from "react";
import { useContract, useTokenBalance } from "@thirdweb-dev/react";
import { BigNumber, ethers, utils } from "ethers";
import Footerbox from "./components/Footerbox";
import { parseIneligibility } from "./utils/parseIneligibility";
import Swal from "sweetalert2";
import BreadCrumb from "./components/breadcrumb";
import AccountabilityCard from "./components/AccountabilityCard";


export default function Home() {
  const address = useAddress();
  const { contract: tokenContract, isLoading: loadingToken } = useContract(tokendropcontract, "token-drop");
  const { data: tokenBalance, isLoading: loadingTokenBalance } = useTokenBalance(tokenContract, address);

  const {
    contract
  } = useContract(submitWalletcontracts);


  const manualUsdRate = 0.80;
  const [usdPrice, setUsdPrice] = useState<number | null>(null);
  const [quantity, setQuantity] = useState(150000);
  const { data: contractMetadata } = useContractMetadata(tokenContract);
  const claimedSupply = useTokenSupply(tokenContract);
  const { data: tokenSupply, isLoading: loadingTokenSupply } = useTokenSupply(tokenContract);


  const [buyAmount, setBuyAmount] = useState(0.00000000001);
  const [message, setMessage] = useState<string>("");

  const { 
    data: totalCoffees, 
    isLoading: loadingTotalCoffee 
  } = useContractRead(contract, "getTotalCoffee");

  const { 
    data: recentCoffee, 
    isLoading: loadingRecentCoffee 
  } = useContractRead(contract, "getAllCoffee");

  const convertDate = (timestamp: bigint) => {
    const timestampNumber = Number(timestamp);
    return new Date(timestampNumber * 1000).toLocaleString();
};
 
  const [tasksCompleted, setTasksCompleted] = useState({
    telegramChannel: false,
    telegramGroup: false,
    // followOnX: false,
    likeOnFacebook: false,
    followOnCMC: false,
    claimTokens: false,
  });

  const handleTaskClick = (taskName: string) => {
    setTasksCompleted((prevState) => ({
      ...prevState,
      [taskName]: true,
    }));
  };

  const allTasksCompleted = Object.values(tasksCompleted).every(Boolean);


  const claimConditions = useClaimConditions(tokenContract);
  const activeClaimCondition = useActiveClaimConditionForWallet(tokenContract, address);
  const claimerProofs = useClaimerProofs(tokenContract, address || "");
  const claimIneligibilityReasons = useClaimIneligibilityReasons(tokenContract, {
    quantity,
    walletAddress: address || "",
  });

  const totalAvailableSupply = useMemo(() => {
    try {
      return BigNumber.from(activeClaimCondition.data?.availableSupply || 0);
    } catch {
      return BigNumber.from(1_000_000_000);
    }
  }, [activeClaimCondition.data?.availableSupply]);

  const numberClaimed = useMemo(() => {
    return BigNumber.from(claimedSupply.data?.value || 0).toString();
  }, [claimedSupply]);

  const numberTotal = useMemo(() => {
    const n = totalAvailableSupply.add(BigNumber.from(claimedSupply.data?.value || 0));
    if (n.gte(1_000_000_000)) {
      return "";
    }
    return n.toString();
  }, [totalAvailableSupply, claimedSupply]);

  const priceToMint = useMemo(() => {
    if (quantity) {
      const bnPrice = BigNumber.from(activeClaimCondition.data?.currencyMetadata.value || 0);
      return `${utils.formatUnits(bnPrice.mul(quantity).toString(), activeClaimCondition.data?.currencyMetadata.decimals || 18)} ${activeClaimCondition.data?.currencyMetadata.symbol}`;
    }
  }, [
    activeClaimCondition.data?.currencyMetadata.decimals,
    activeClaimCondition.data?.currencyMetadata.symbol,
    activeClaimCondition.data?.currencyMetadata.value,
    quantity,
  ]);

  const maxClaimable = useMemo(() => {
    let bnMaxClaimable;
    try {
      bnMaxClaimable = BigNumber.from(activeClaimCondition.data?.maxClaimableSupply || 0);
    } catch (e) {
      bnMaxClaimable = BigNumber.from(1_000_000_000);
    }

    let perTransactionClaimable;
    try {
      perTransactionClaimable = BigNumber.from(activeClaimCondition.data?.maxClaimablePerWallet || 0);
    } catch (e) {
      perTransactionClaimable = BigNumber.from(1_000_000_000);
    }

    if (perTransactionClaimable.lte(bnMaxClaimable)) {
      bnMaxClaimable = perTransactionClaimable;
    }

    const snapshotClaimable = claimerProofs.data?.maxClaimable;

    if (snapshotClaimable) {
      if (snapshotClaimable === "0") {
        // allowed unlimited for the snapshot
        bnMaxClaimable = BigNumber.from(1_000_000_000);
      } else {
        try {
          bnMaxClaimable = BigNumber.from(snapshotClaimable);
        } catch (e) {
          // fall back to default case
        }
      }
    }

    let max;
    if (totalAvailableSupply.lt(bnMaxClaimable)) {
      max = totalAvailableSupply;
    } else {
      max = bnMaxClaimable;
    }

    if (max.gte(1_000_000_000)) {
      return 1_000_000_000;
    }
    return max.toNumber();
  }, [
    claimerProofs.data?.maxClaimable,
    totalAvailableSupply,
    activeClaimCondition.data?.maxClaimableSupply,
    activeClaimCondition.data?.maxClaimablePerWallet,
  ]);

  const isSoldOut = useMemo(() => {
    try {
      return (
        (activeClaimCondition.isSuccess &&
          BigNumber.from(activeClaimCondition.data?.availableSupply || 0).lte(0)) ||
        numberClaimed === numberTotal
      );
    } catch (e) {
      return false;
    }
  }, [
    activeClaimCondition.data?.availableSupply,
    activeClaimCondition.isSuccess,
    numberClaimed,
    numberTotal,
  ]);

  const canClaim = useMemo(() => {
    return (
      activeClaimCondition.isSuccess &&
      claimIneligibilityReasons.isSuccess &&
      claimIneligibilityReasons.data?.length === 0 &&
      !isSoldOut
    );
  }, [
    activeClaimCondition.isSuccess,
    claimIneligibilityReasons.data?.length,
    claimIneligibilityReasons.isSuccess,
    isSoldOut,
  ]);

  const isLoading = useMemo(() => {
    return activeClaimCondition.isLoading || !tokenContract;
  }, [activeClaimCondition.isLoading, tokenContract]);

  const buttonLoading = useMemo(
    () => isLoading || claimIneligibilityReasons.isLoading,
    [claimIneligibilityReasons.isLoading, isLoading]
  );
  
  const buttonText = useMemo(() => {
    if (isSoldOut) {
      return "Checking Status";
    }

    if (canClaim) {
      const pricePerToken = BigNumber.from(activeClaimCondition.data?.currencyMetadata.value || 0);
      if (pricePerToken.eq(0)) {
        return "Claim (Free)";
      }
      return `Claim (${priceToMint})`;
    }
    if (claimIneligibilityReasons.data?.length) {
      return parseIneligibility(claimIneligibilityReasons.data, quantity);
    }
    if (buttonLoading) {
      return "Checking eligibility...";
    }

    return "Claiming not available";
  }, [
    isSoldOut,
    canClaim,
    claimIneligibilityReasons.data,
    buttonLoading,
    activeClaimCondition.data?.currencyMetadata.value,
    priceToMint,
    quantity,
  ]);

  useEffect(() => {
    // Calculate the USD price based on the manual rate
    if (tokenBalance?.value !== undefined) {
      const usdPrice = parseFloat(ethers.utils.formatEther(tokenBalance.value)) * manualUsdRate;
      setUsdPrice(usdPrice);
    }
  }, [tokenBalance, manualUsdRate]);


  if (!address) {
    return (
      <body>
        <NavBar />
        <BreadCrumb />
        <main className="main">
          <div className="container">
            <div className="flex flex-col w-full lg:flex-row gap-4 mt-4 allcenter">
              <div className="grid">
                <div className="card">
                  <div className="card-text">
                    <h1 className="cuz-text cuz-grad mt-4 mb-4 frogs-heading">Welcome to participate in Frogs Pepe Airdrop!</h1>

                    <center><img src="https://frogspepe.xyz/images/3.png" alt=""></img></center>
                    
                    <p className="mt-4 cuz-text cuz-grad frogs-text">Frogs Pepe Drop isn't just another meme token. 
                    It's an immersive world where memes and legends collide, bringing together the kingdom of Pepe the Frog's frens. </p>

                    <div className="details cuz-text cuz-grad frogs-text mt-4 mb-4">
            <p>🎉 <strong><u>Whitelist Airdrop Details</u></strong> 🎉</p>
            <p>🗓️ <strong>End Date</strong>: July 20, 2024</p>
            <p>🏆 <strong>Reward</strong>: Claim up to <span className="highlight">150,000 FPD</span> tokens!</p>
            <p>✅ <strong>Eligibility</strong>: All valid participants on the whitelist will be rewarded.</p>
           <br/>
            <p>🔗 <strong>How to Claim</strong>:</p>
            <ol>
                <li>Connect your wallet.</li>
                <li>Follow the instructions to start claiming your FPD tokens.</li>
            </ol>
        </div>

                    <p className="text-center mt-4 mb-4 cuz-text">Please connect your wallet to start claiming.</p>
                    <center><ConnectWallet
        style={{ color: "white", background:"green" }}
        theme={"dark"}
        className="lg:hidden"
        btnTitle={"Claim Your FPD Tokens"}
        modalTitle={"Connect Wallet"}
        switchToActiveChain={true}
        modalSize={"compact"}
        welcomeScreen={{}}
        termsOfServiceUrl={
          "https://frogspepe.xyz"
        }
        privacyPolicyUrl={
          "https://frogspepe.xyz"
        }
      /></center>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footerbox />
        </main>
      </body>
    );
  }

  return (
    <body>
      <NavBar />
      <main className="main">
        <div className="container">
          <div className="flex flex-col w-full lg:flex-row gap-4 mt-4 allcenter">
            <div className="grid">
              <div className="card">
                <div className="card-text">
                  <div className="header">
                  <h1 className="text-center cuz-text cuz-grad header-text text-black">Hop into Frogs Pepe: <br/>Your Portal to Epic Airdrops and Ribbiting Incubation</h1>
                  <p className="text-black text-center text-sm header-desc mt-2 mb-2">Frogs Pepe is here to power your meme crypto adventure with a blend of epic airdrops and incubatior. </p>
                        
                        <div className="">
                        
                        <div className="flex items-center justify-between p-4 bg-white rounded-card dark:bg-darker">
  <div>
    <h6 className="text-xs font-medium leading-none tracking-wider text-black uppercase">
      My FPD Balance
    </h6>
    {!loadingToken && !loadingTokenBalance && tokenBalance?.value !== undefined ? (
      <>
        <span className="text-xl text-black font-semibold">
          {`${parseFloat(ethers.utils.formatEther(tokenBalance.value)).toLocaleString()} ${tokenBalance.symbol}`}
        </span>
        {usdPrice !== null && (
          <span className="inline-block px-2 py-px ml-2 text-xs text-green-500 bg-green-100 rounded-md">
            {`${usdPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} USDT`}
          </span>
        )}
      </>
    ) : (
      <div className="skeleton-loader">
        <span className="text-xl text-black font-semibold">Loading...</span>
      </div>
    )}
  </div>
  <div>
    <span>
      <svg className="w-12 h-12 text-gray-300 dark:text-primary-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
      </svg>
    </span>
  </div>
</div>

<div className="flex items-center justify-between mt-4 mb-4 p-4 bg-white rounded-card dark:bg-darker">
                  <div>
                    <h6 className="text-xs font-medium leading-none tracking-wider text-black uppercase">
                    Total Frogs Claimed
                    </h6>
                    {!loadingTokenSupply !== undefined ? (
                               <span className="text-xl text-black font-semibold">{tokenSupply?.displayValue ? new Intl.NumberFormat('en-US', { style: 'decimal' }).format(parseFloat(tokenSupply.displayValue)) : 'Loading'} {tokenSupply?.symbol}
                               </span>
                    ) : (
                                <span className="text-xl text-black font-semibold">Loading...</span>        
                      )}
                  </div>
                  <div>
                    <span>
                      <svg className="w-12 h-12 text-gray-300 dark:text-primary-dark" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                      </svg>
                    </span>
                  </div>
                </div>
                       
          {/* <div className="stat">
						<div className="stat-title text-center text-black">Participants</div>
						<div className="stat-value text-black"> 
            {!loadingTotalCoffee !== undefined ? (
                            <>
                              <div className="stat-value text-3xl text-center text-black">
                              {totalCoffees?.toString()}
                              </div>
                            </>
                          ) : (
                            <div className="skeleton-loader">
                              <div className="stat-value text-center text-black">Loading...</div>
                            </div>
                          )}
                          </div>
					</div> */}
          {/* <div className="stat">
						<div className="stat-title text-black text-center">Total FPD Minted by Community </div>
						<div className="stat-value text-black"> 
            {!loadingTokenSupply !== undefined ? (
                            <>
                              <h4 className="stat-value text-center text-3xl text-black">
                              {tokenSupply?.displayValue ? new Intl.NumberFormat('en-US', { style: 'decimal' }).format(parseFloat(tokenSupply.displayValue)) : 'Loading'} {tokenSupply?.symbol}
                              </h4>
                            </>
                          ) : (
                            <div className="skeleton-loader">
                              <div className="stat-value text-center text-black">Loading...</div>
                            </div>
                          )}</div>
					</div> */}
          </div> 

          <h1 className="header-text text-center cuz-text cuz-grad">Complete Frogs Pepe Quests</h1>         
 <ul className="menu bg-white w-full rounded-box">
<li>
<a className="text-black text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Follow Telegram Channel
</a>
{tasksCompleted.telegramChannel ? (
 <span className="task-btn check-mark">✓</span>
) : (
 <a href="https://t.me/enterfrogspepe" target="_blank" className="task-btn" onClick={() => handleTaskClick('telegramChannel')}>GO</a>
)}
</li>
<li>
<a className="text-black text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Join Telegram Group
</a>
{tasksCompleted.telegramGroup ? (
 <span className="task-btn check-mark">✓</span>
) : (
 <a href="https://t.me/frogspepechat" target="_blank" className="task-btn" onClick={() => handleTaskClick('telegramGroup')}>GO</a>
)}
</li>
{/* <li>
<a className="text-black text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Follow Frogs Pepe on X
</a>
{tasksCompleted.followOnX ? (
 <span className="task-btn check-mark">✓</span>
) : (
 <a href="https://x.com/frogspepeweb3" target="_blank" className="task-btn" onClick={() => handleTaskClick('followOnX')}>GO</a>
)}
</li> */}
<li>
<a className="text-black text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Like Facebook Page.
</a>
{tasksCompleted.likeOnFacebook ? (
 <span className="task-btn check-mark">✓</span>
) : (
 <a href="https://www.facebook.com/profile.php?id=61558424458048&mibextid=ZbWKwL" target="_blank" className="task-btn" onClick={() => handleTaskClick('likeOnFacebook')}>GO</a>
)}
</li>
<li>
<a className="text-black text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Follow us on CMC
</a>
{tasksCompleted.followOnCMC ? (
 <span className=" task-btn check-mark">✓</span>
) : (
 <a href="https://coinmarketcap.com/community/profile/frogspepe/" target="_blank" className="task-btn" onClick={() => handleTaskClick('followOnCMC')}>GO</a>
)}
</li>
{/* <li>
<a className="text-black text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Submit Frogs Pepe Drop Wallet Address
</a>
<input 
                className="input w-full invisible"
                type="number" 
                value={buyAmount}
                onChange={(e) => setBuyAmount(Number(e.target.value))}
                step={0.00000001}
                hidden
              />
               <input 
                className="input  walletaddress w-full mb-2"
                placeholder="Your BEP20 Wallet Address" 
                maxLength={80} 
                value={message} 
                onChange={(e) => setMessage(e.target.value)}
                />

               <Web3Button
                    contractAddress={submitWalletcontracts}
                    action={(contract) => {
                      contract.call("buyMeACoffee", [message], {value: ethers.utils.parseEther("0.000001")})
                    }}
                    onSuccess={() => Swal.fire("Confirm Submission")}
                    onError={(err) => Swal.fire("OOps Try Again")}
                    style={{ color: "white", background:"green" }}
                  >{"Submit Wallet"}
                  </Web3Button>
                  
               
</li> */}
<li>
<a className="text-black text-task mt-2 mb-2">
 <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
 </svg>
 Claim 150,000 FPD Tokens
</a>
{tasksCompleted.claimTokens ? (
 <span className="task-btn check-mark">✓</span>
) : (
 <button className="task-btn" onClick={() => handleTaskClick('claimTokens')}>GO</button>
)}
</li>

</ul> 

{allTasksCompleted && (
         <section className="">
         <div className="stat text-black">
         {isLoading ? (
      <p className="text-center">Loading Please Wait...</p>
      ) : (
        <>
{contractMetadata?.image && (
<center><img
 src={contractMetadata?.image}
 alt={contractMetadata?.name!}
 width={200}
 height={200}
/>
</center>
)}


<h1 className="text-center cuz-text cuz-grad header-text">Claim The {contractMetadata?.name}</h1>
<p className=" text-center cuz-text cuz-grad text-sm header-desc mt-2 mb-2">
<span>{contractMetadata?.description}</span>
You Can Claim Airdrop Multiple Times, Only For Next 24 Hours
</p>
</>
)}

           <input
             type="number"
             placeholder="Enter amount to claim"
             onChange={(e) => {
               const value = parseInt(e.target.value);
               if (value > maxClaimable) {
                 setQuantity(maxClaimable);
               } else if (value < 1) {
                 setQuantity(1);
               } else {
                 setQuantity(value);
               }
             }}
             value={quantity}
             className="form-input mt-4 mb-4"
             
           />

           <Web3Button
             theme="dark"
             contractAddress={tokendropcontract}
             action={(contract) => contract.erc20.claim(quantity)}
             onSuccess={() => Swal.fire("Frogs Pepe Drop Claim Successfully")}
             onError={(err) => Swal.fire("No Gas Fees Try Again")}
             style={{ color: "white", background:"green" }}
           >
             {buttonText}
           </Web3Button>

           {(claimConditions.data &&
             claimConditions.data.length > 0 &&
             activeClaimCondition.isError) ||
            (activeClaimCondition.data &&
            activeClaimCondition.data.startTime > new Date() && (
                <p className="text-black text-center">Drop is starting soon. Please check back later.</p>
             ))}

             {claimConditions.data?.length === 0 ||
             (claimConditions.data?.every((cc) => cc.maxClaimableSupply === "0") && (
                  <p className="text-black text-center">
                This drop is not ready to be minted yet. (No claim condition set)
                </p>
                 ))}
         </div>
       </section>
      )}
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footerbox />
        </div>
      </main>
    </body>
  );
}
